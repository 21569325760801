<template>
  <cargill-section :layer="3" class="total-plan-consolidated">
    <cargill-section-header
      class="total-plan-program-consolidated__header"
      color="#2B323F"
    >
      {{ translate('title') }}
    </cargill-section-header>

    <bc-row dense>
      <bc-col>
        <program-pD-consolidated :base-filter="baseFilter" />
      </bc-col>
    </bc-row>
  </cargill-section>
</template>

<script>
import { CargillSection, CargillSectionHeader } from '../cargill-section'

import { ProgramPDConsolidated } from './consolidateds'

export default {
  name: 'TotalPlanDashboardPDConsolidated',
  data() {
    return {
      consolidated: {},
      kpis: []
    }
  },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  components: {
    CargillSection,
    CargillSectionHeader,
    ProgramPDConsolidated
  },
  created() {},
  methods: {
    translate(key) {
      return this.$t(
        'controlTower.pages.totalPlanDashboard.pdConsolidated.' + key
      )
    }
  }
}
</script>

<style scoped lang="scss">
.total-plan-execution-consolidated {
  &__header {
    margin-bottom: 10px;
  }
}
.total-plan-consolidated {
  height: 100%;
}
</style>
