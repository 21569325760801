<template>
  <bc-container class="total-plan-dashboard">
    <cargill-section v-if="publishedScenario == 'published'">
      <total-plan-dashboard-header
        :filter-definition="filterDefinition"
        :period="selectedPeriod"
        :year="selectedCropYear"
        v-on:filter-change="validateFilter"
        @exportPpt="exportPpt"
      />

      <cargill-section-content>
        <div class="total-plan-dashboard__consolidated">
          <bc-row dense>
            <bc-col
              v-bind:cols="
                baseFilter.cropYear && baseFilter.cropYear.length > 1 ? 12 : 6
              "
            >
              <total-plan-dashboard-export-consolidated
                :base-filter="baseFilter"
              />
            </bc-col>
            <bc-col>
              <total-plan-dashboard-domestic-market-consolidated
                :base-filter="baseFilter"
              />
            </bc-col>
          </bc-row>
          <bc-row>
            <bc-col
              v-bind:cols="
                baseFilter.cropYear && baseFilter.cropYear.length > 1 ? 12 : 6
              "
            >
              <total-plan-dashboard-origination-consolidated
                :base-filter="baseFilter"
              />
            </bc-col>
            <bc-col>
              <total-plan-dashboard-pD-consolidated :base-filter="baseFilter" />
            </bc-col>
            <bc-col>
              <total-plan-dashboard-storage-cost-consolidated
                :base-filter="baseFilter"
              />
            </bc-col>
          </bc-row>
        </div>
        <div class="total-plan-dashboard__charts">
          <total-plan-dashboard-export-program
            ref="exportprogram"
            :base-filter="baseFilter"
          />
          <total-plan-dashboard-booking-capacity
            ref="bookingcapacity"
             @unit-change="onChangeMeasurementUnit"
            :base-filter="baseFilter"
            :unit="measurementUnit"
          />
          <total-plan-dashboard-domestic-market-program
            ref="ProgramDomestic"
            :base-filter="baseFilter"
          />
          <total-plan-dashboard-origination-program
            ref="originationprogram"
            :base-filter="baseFilter"
          />
        </div>
      </cargill-section-content>
    </cargill-section>
    <div v-else>
      <bc-card class="empty-data-info-container">
        <bc-message
          v-if="publishedScenario != ''"
          type="info"
          icon="fa-info-square"
          :title="$t(`application.misc.${publishedScenario}`)"
          :actions="[]"
        >
        </bc-message>
      </bc-card>
    </div>
  </bc-container>
</template>
<script>
import {
  CargillSection,
  CargillSectionContent
} from '../components/cargill-section'
import {
  TotalPlanDashboardHeader,
  TotalPlanDashboardDomesticMarketProgram,
  TotalPlanDashboardOriginationProgram,
  TotalPlanDashboardBookingCapacity,
  TotalPlanDashboardExportProgram,
  TotalPlanDashboardExportConsolidated,
  TotalPlanDashboardDomesticMarketConsolidated,
  TotalPlanDashboardOriginationConsolidated,
  TotalPlanDashboardPDConsolidated,
  TotalPlanDashboardStorageCostConsolidated
} from '../components/total-plan'
import service from '../api/totalPlanService'
import { BcContainer, BcCard, BcMessage } from '@brain/core'
import { dashboardUtils } from '@cargill/shared'
import moment from 'moment'
import pptxgen from 'pptxgenjs'

export default {
  name: 'TotalPlanDashboard',
  components: {
    BcContainer,
    BcCard,
    BcMessage,
    CargillSection,
    CargillSectionContent,
    TotalPlanDashboardHeader,
    TotalPlanDashboardDomesticMarketProgram,
    TotalPlanDashboardOriginationProgram,
    TotalPlanDashboardBookingCapacity,
    TotalPlanDashboardExportProgram,
    TotalPlanDashboardExportConsolidated,
    TotalPlanDashboardDomesticMarketConsolidated,
    TotalPlanDashboardOriginationConsolidated,
    TotalPlanDashboardPDConsolidated,
    TotalPlanDashboardStorageCostConsolidated
  },
  data() {
    return {
      filter: {
        period: [],
        cropYear: [dashboardUtils.getActualCropYear()]
      },
      measurementUnit: dashboardUtils.getUnityTonne(),
      actualCropYear: '',
      selectedCropYear: '',
      selectedPeriod: null,
      publishedScenario: ''
    }
  },
  computed: {
    baseFilter() {
      const vm = this
      return {
        startDate: this.filter.period[0],
        endDate: this.filter.period[1],
        cropYear: this.filter.cropYear,
        unity: this.measurementUnit.value,
        processError: (errors) => {
          errors?.forEach((error) => {
            vm.notify.error({
              title: error
            })
          })
        }
      }
    },
    filterDefinition() {
      const period = dashboardUtils.initFilterRangeTotalPlan()
      return [
        {
          name: 'period',
          type: 'dateRange',
          minDate: period[0],
          maxDate: period[1],
          defaultValue: []
        },
        {
          name: 'cropYear',
          label: this.$t('controlTower.pages.cockpitExecution.harvest'),
          type: 'multiSelect',
          value: dashboardUtils.getCropYears(),
          defaultValue: [this.actualCropYear]
        }
      ]
    }
  },
  async mounted() {
    this.publishedScenario = await service.checkPublishedScenario(
      'checkPublishedScenario'
    )
  },
  methods: {
    onChangeMeasurementUnit(newValue) {
      this.measurementUnit = newValue
    },
    validateFilter(newValue) {
      if (this.validateCropYear(newValue) && this.validatePeriod(newValue)) {
        this.filter = newValue
        this.selectedCropYear = newValue.cropYear.sort().join(', ')
        this.selectedPeriod = newValue.period.map((date) =>
          moment(date).format('DD/MM/YYYY')
        )
      }
    },
    async exportPpt() {
      const pptx = new pptxgen()
      pptx.layout = 'LAYOUT_WIDE'

      pptx.defineSlideMaster({
        title: 'MASTER_SLIDE',
        background: { color: 'FFFFFF' },
        objects: [
          {
            placeholder: {
              options: {
                name: 'title',
                type: 'title',
                x: 0.21,
                y: 0.2,
                w: '96.5%',
                h: '8%',
                align: 'left',
                fontSize: 28,
                bold: true
              },
              text: '(Título)'
            }
          },
          {
            rect: {
              x: 0.21,
              y: 1,
              w: '96.5%',
              h: '75%',
              line: { width: '1', color: '008B44' }
            }
          },
          {
            rect: {
              x: 0.2,
              y: 6.9,
              w: 0.4,
              h: 0.4,
              fill: { color: '999999' }
            }
          },
          {
            rect: {
              x: 0.7,
              y: 6.9,
              w: '87.5%',
              h: 0.4,
              fill: { color: 'F1F1F1' }
            }
          },
          {
            image: {
              x: 12.5,
              y: 6.95,
              w: 0.5845,
              h: 0.2625,
              path: require('../assets/logo-cargill.png')
            }
          }
        ],
        slideNumber: {
          x: 0.25,
          y: 6.95,
          fontSize: 12,
          bold: true,
          color: 'FFFFFF'
        }
      })

      this.generateSlide(
        pptx,
        this.$refs.exportprogram,
        'controlTower.pages.totalPlanDashboard.pptExport.exportConsolidatedTitle'
      )
      this.generateSlide(
        pptx,
        this.$refs.bookingcapacity,
        'controlTower.pages.totalPlanDashboard.pptExport.bookingCapacity'
      )
      this.generateSlide(
        pptx,
        this.$refs.ProgramDomestic,
        'controlTower.pages.totalPlanDashboard.pptExport.domesticMarketConsolidatedTitle'
      )
      this.generateSlide(
        pptx,
        this.$refs.originationprogram,
        'controlTower.pages.totalPlanDashboard.pptExport.originationConsolidatedTitle'
      )

      pptx.writeFile({
        fileName: 'IBPMonthlyReview-' + moment().format('DD/MM/YYYY')
      })
    },
    validateCropYear(newValue) {
      if (newValue.cropYear.length > 2) {
        this.notify.error({
          title: this.$t('controlTower.errors.cropYearMax')
        })
        return false
      } else if (!this.validateCropYearSubsequent(newValue.cropYear.sort())) {
        this.notify.error({
          title: this.$t('controlTower.errors.cropYearSubsequent')
        })
        return false
      }
      return true
    },
    async generateSlide(pptx, refs, title) {
      const svgPeriodic = await refs.getChildRef().onGetSvgData()
      const svgConsolidated = await refs
        .getChildConsolidatedRef()
        .onGetSvgData({ w: 400, h: 400 })

      const slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slide.addText(this.$t(title), { placeholder: 'title' })
      const w = 9
      const h = 3.3
      const x = 0.4
      const y = 1.7
      const wC = 3.3
      const hC = 3.3
      const xC = 9.5
      const yC = 1.7

      slide.addText(moment().format('DD-MMM'), {
        w: '10%',
        h: '5%',
        x: 0.3,
        y: 1.15,
        align: 'left',
        fontSize: 20,
        isTextBox: true
      })
      slide.addImage({ data: svgPeriodic, w: w, h: h, x: x, y: y })
      slide.addImage({
        data: svgConsolidated,
        w: wC,
        h: hC,
        x: xC,
        y: yC
      })

      slide.addText('', {
        shape: pptx.ShapeType.rect,
        w: '70%',
        h: 1.3,
        x: 2,
        y: 5.55,
        fill: { color: 'F2F4EB' },
        line: { width: '1', color: '008B44' },
        align: 'left',
        fontSize: 10,
        bullet: true,
        isTextBox: true
      })
    },
    validateCropYearSubsequent(cropYear) {
      return !(cropYear.length == 2 && cropYear[1] != cropYear[0] + 1)
    },
    validatePeriod(newValue) {
      if (newValue.period.length == 1) {
        this.notify.error({
          title: this.$t('controlTower.errors.requiredEndPeriod')
        })
        return false
      } else if (
        !this.validatePeriodByCropYear(
          newValue.period,
          newValue.cropYear.sort()
        )
      ) {
        this.notify.error({
          title: this.$t('controlTower.errors.periodOutOfCrop')
        })
        return false
      }
      return true
    },
    validatePeriodByCropYear(period, cropYear) {
      if (period.length == 2 && cropYear.length > 0 && cropYear.length <= 2) {
        const startDate = moment(period[0])
        const endDate = moment(period[1])
        const startDateCropYear = this.getStartDateCropYear(cropYear)
        const endDateCropYear = this.getEndDateCropYear(cropYear)
        return !(startDate < startDateCropYear || endDate > endDateCropYear)
      }
      return true
    },
    getStartDateCropYear(cropYear) {
      return moment(`${cropYear[0]}-08-01`)
    },
    getEndDateCropYear(cropYear) {
      return cropYear.length == 2
        ? moment(`${cropYear[1] + 1}-07-31`)
        : moment(`${cropYear[0] + 1}-07-31`)
    }
  },
  created() {
    this.actualCropYear = dashboardUtils.getActualCropYear()
    this.selectedCropYear = this.actualCropYear.toString()
  }
}
</script>

<style lang="scss" scoped>
.total-plan-dashboard {
  &__charts {
    margin-top: 10px;
  }
}
.empty-data-info-container {
  padding: 230px 0 !important;
}
</style>
