import { dashboardUtils, api } from '@cargill/shared'

const baseURL = '/api/controltower/totalPlan'

const service = {}

service.checkPublishedScenario = async (endpoint) => {
  const url = `${baseURL}/${endpoint}`
  const response = await api.get(url)
  return response.data
}

service.getConsolidated = async (endpoint, baseFilter) => {
  const url = `${baseURL}/${endpoint}/consolidated`
  return await dashboardUtils.filterDashboard(baseFilter, url)
}

service.getViewSelectorConfig = async (endpoint) => {
  const url = `${baseURL}/${endpoint}/getViewSelectorConfig`
  const response = await api.get(url)
  return response.data
}

service.getChart = async (endpoint, oldChart, baseFilter) => {
  const url = `${baseURL}/${endpoint}/getChart`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getCompleteChart = async (
  endpoint,
  oldChart,
  oldConsolidatedChart,
  baseFilter
) => {
  const url = `${baseURL}/${endpoint}/getChart`
  return await filterTotalPlanChart(
    oldChart,
    oldConsolidatedChart,
    baseFilter,
    url
  )
}

const filterTotalPlanChart = async (
  oldChart,
  oldConsolidatedChart,
  baseFilter,
  url,
  processChart,
  changeZeroToNull = true
) => {
  const filter = dashboardUtils.buildFilterChart(oldChart, baseFilter)
  const result = await dashboardUtils.filterDashboard(filter, url)
  if (processChart && result.chart) {
    processChart(result.chart)
  }
  if (processChart && result.consolidatedChart) {
    processChart(result.consolidatedChart)
  }
  dashboardUtils.processChartInput(result.chart, oldChart, changeZeroToNull)
  dashboardUtils.processChartInput(
    result.consolidatedChart,
    oldConsolidatedChart,
    changeZeroToNull
  )
  return result
}

service.getChartExcel = async (endpoint, chartRef, baseFilter) => {
  const url = `${baseURL}/${endpoint}/getChartExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.allowActions = {
  export: false,
  filter: false
}

service.baseFilter = {
  cropYear: undefined
}

export default service
