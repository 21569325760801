<template>
  <cargill-section :layer="3" class="total-plan-execution-consolidated">
    <cargill-section-header
      class="total-plan-execution-consolidated__header"
      color="#2B323F"
    >
    {{ consolidated.title }}
    </cargill-section-header>

    <cargill-section-content>
      <bc-container class="total-plan-execution-consolidated__container">
        <bc-row dense>
          <div
            :style="`display: contents`"
            v-if="consolidated.kpis && consolidated.kpis.length == 1"
          >
            <bc-col
              v-for="kpi in consolidated.kpis"
              :key="kpi.title"
              cols="12"
              xl="12"
            >
              <dashboard-kpi :kpi="kpi"></dashboard-kpi>
            </bc-col>
          </div>
          <bc-col
            v-else
            v-for="kpi in consolidated.kpis"
            :key="kpi.title"
            cols="12"
            xl="6"
          >
            <dashboard-kpi :kpi="kpi"></dashboard-kpi>
          </bc-col>
        </bc-row>
      </bc-container>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../../cargill-section'
import { DashboardKpi } from '../../dashboards'

import service from '../../../api/totalPlanService'

export default {
  name: 'ExecutionDomesticMarketConsolidated',
  data() {
    return {
      consolidated: {},
      kpis: []
    }
  },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  components: {
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    DashboardKpi
  },
  created() {},
  mounted() {
    this.loadConsolidated()
  },
  methods: {
    async loadConsolidated() {
      this.consolidated = await service.getConsolidated(
        'executionDomesticMarket',
        this.baseFilter
      )
    }
  },
  watch: {
    baseFilter() {
      this.loadConsolidated()
    }
  }
}
</script>

<style scoped lang="scss">
.total-plan-execution-consolidated {
  padding: 10px 0 0;
  height: 100%;
  &__header {
    margin-bottom: 10px;
  }

  .cargill-section-content {
    padding: 12px 15px;
  }

  &__container {
    padding: 0px;
    height: 100%;
    .row {
      min-height: 225px;
      height: 102%;
    }
    .col-xl-6 {
      padding: 4px;
    }
    .col-12 {
      padding: 4px;
    }
  }
}
</style>
