<template>
  <div class="dashboard-view-selector">
    <bc-popover v-model="isOpen" placement="bottom-end">
      <template v-slot:activator="{ on, attrs }">
        <bc-btn
          v-bind="attrs"
          v-on="on"
          icon
          x-small
          class="dashboard-view-selector__button"
        >
          <bc-icon size="14" color="light-grey">fas fa-eye</bc-icon>
        </bc-btn>
      </template>

      <template v-slot:header>
        <div class="dashboard-view-selector__header">
          <div class="dashboard-view-selector__title">
            {{ $t('controlTower.pages.totalPlanDashboard.viewSelector.title') }}
          </div>

          <bc-btn
            class="dashboard-view-selector__clear-selector"
            color="link-grey"
            @click="onReset"
          >
            {{ $t('controlTower.pages.totalPlanDashboard.viewSelector.clear') }}
          </bc-btn>
        </div>
      </template>

      <div class="dashboard-view-selector__content">
        <!-- SELECT -->
        <div class="dashboard-filter-input">
          <bc-radio-group
            v-model="view"
            column
            :label="
              $t('controlTower.pages.totalPlanDashboard.viewSelector.viewType')
            "
          >
            <bc-radio
              v-for="viewOption in resultViewsOptions"
              :key="viewOption.value"
              :label="viewOption.text"
              :value="viewOption.value"
            >
            </bc-radio>
          </bc-radio-group>
        </div>
        <div class="dashboard-filter-input" :id="inputId">
          <bc-select
            v-if="showSeries()"
            filled
            item-text="text"
            item-value="value"
            :menu-props="{ auto: true }"
            :returnObject="false"
            :label="
              $t('controlTower.pages.totalPlanDashboard.viewSelector.series')
            "
            v-model="series"
            :items="resultSeriesOptions"
            :multiple="false"
            :attach="`#${inputId}`"
          ></bc-select>
        </div>

        <div class="dashboard-view-selector__actions">
          <bc-btn color="link-blue" @click="onCancel">
            {{ $t('application.actions.cancel') }}
          </bc-btn>

          <bc-btn color="primary" @click="onApply">
            {{ $t('application.actions.save') }}
          </bc-btn>
        </div>
      </div>
    </bc-popover>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BcBtn,
  BcPopover,
  BcIcon,
  BcSelect,
  BcRadioGroup,
  BcRadio
} from '@brain/core'

let inputIdGenerator = 1

export default defineComponent({
  name: 'TotalPlanDashboardViewSelector',
  components: {
    BcBtn,
    BcIcon,
    BcPopover,
    BcSelect,
    BcRadioGroup,
    BcRadio
  },
  props: {
    shouldShowSeries: {
      type: Function
    },
    viewDefault: {
      type: String,
      required: true
    },
    viewsOptions: {
      type: Array,
      required: true
    },
    seriesDefault: {
      type: String,
      required: true
    },
    seriesOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      view: this.viewDefault,
      series: this.seriesDefault,
      inputId: `TotalPlanDashboardViewSelector-${inputIdGenerator++}`
    }
  },
  methods: {
    onApply() {
      this.$emit('view-selector-change', {
        view: this.view,
        series: this.showSeries() ? this.series : null
      })
      this.isOpen = false
    },
    onReset() {
      this.view = this.viewDefault
      this.series = this.seriesDefault
    },
    onCancel() {
      this.isOpen = false
    },
    showSeries() {
      if (this.shouldShowSeries) {
        return this.shouldShowSeries(this.view)
      }
      return this.view != 'default'
    }
  },
  computed: {
    resultSeriesOptions() {
      return (this.seriesOptions ?? []).map((x) => {
        return {
          text: this.$t(
            `application.enums.ibpMonthlyReviewDashboardSeries.${x}`
          ),
          value: x
        }
      })
    },
    resultViewsOptions() {
      return (this.viewsOptions ?? []).map((x) => {
        return {
          text: this.$t(`application.enums.ibpMonthlyReviewDashboardView.${x}`),
          value: x
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-filter-input {
  position: relative;

  & + & {
    margin-top: 10px;
  }
}

.v-input {
  &.v-input--radio-group {
    background: var(--bc-bg-filds) !important;
    padding: 10px 10px !important;

    & > .v-input__control {
      & > .v-input__slot {
        background: transparent !important;

        &::before,
        &::after {
          border-color: var(--bc-light-grey) !important;
        }
      }
    }
  }
}

.dashboard-view-selector {
  margin: -8px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    color: #959db5;
    font-weight: bold;
  }

  &__clear-selector {
    &#{&}#{&} {
      background-color: transparent;
      padding: 0 20px !important;
      min-height: 48px;
      height: 48px;
      margin: -12px -20px -12px 0;
    }
  }

  &__content {
    min-width: 400px;
    padding-top: 10px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
}
</style>
