<template>
  <div class="total-plan-dashboard-header__action">
    <span class="primary--text" v-if="showViewSelector">
      {{
        $t(`application.enums.ibpMonthlyReviewDashboardView.${viewSelected}`)
      }}
    </span>
    <bc-divider vertical class="mx-2" v-if="showViewSelector"></bc-divider>
    <span class="primary--text" v-if="series">
      {{ $t(`application.enums.ibpMonthlyReviewDashboardSeries.${series}`) }}
    </span>
    <bc-divider vertical class="mx-2" v-if="series"></bc-divider>
    <total-plan-dashboard-view-selector
      v-if="showViewSelector"
      v-bind="viewSelectorConfig"
      @view-selector-change="onViewSelectorChange($event)"
    >
    </total-plan-dashboard-view-selector>
    <div v-if="showSelectUnity">
      <dashboard-unit-selector v-model="measurementUnity">
      </dashboard-unit-selector>
    </div>
    <dashboard-filter
      :filters="filterDefinition"
      v-model="$filter"
    ></dashboard-filter>
    <bc-btn
      v-if="visible"
      icon
      x-small
      class="total-plan-dashboard-header__action-btn"
      @click="onExportData"
    >
      <bc-icon size="14" color="light-grey">fas fa-download</bc-icon>
    </bc-btn>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { CargillSectionHeader } from '../cargill-section'
import { DashboardUnitSelector } from '../dashboards'
import { useDateFormatter } from '../../utils/dateFormatter'
import { DashboardFilter } from '../dashboards'
import TotalPlanDashboardViewSelector from './TotalPlanDashboardViewSelector.vue'

export default defineComponent({
  name: 'TotalPlanDashboardHeaderActions',
  components: {
    CargillSectionHeader,
    DashboardUnitSelector,
    DashboardFilter,
    TotalPlanDashboardViewSelector
  },
  props: {
    filterDefinition: {
      type: Array,
      required: true
    },
    viewSelectorConfig: {
      type: Object
    },
    visible: {
      type: Boolean,
      default: true
    },
    showSelectUnity: {
      type: Boolean,
      default: false
    },
    unit: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      filter: {},
      formatDate: useDateFormatter(this),
      view: null,
      series: null
    }
  },
  computed: {
    showViewSelector() {
      return this.viewSelectorConfig != null
    },
    viewSelected() {
      return this.view ?? this.viewSelectorConfig?.viewDefault
    },
    measurementUnity: {
      get() {
        return this.unit
      },
      set(value) {
        this.$emit('unit-change', value)
      }
    },
    $filter: {
      get() {
        return this.filter
      },
      set(newValue) {
        this.$emit('filter-change', { ...newValue })
      }
    }
  },
  methods: {
    translate(key) {
      return this.$t('controlTower.pages.totalPlanDashboard.' + key)
    },
    onExportData() {
      this.$emit('on-export-data')
    },
    onViewSelectorChange(event) {
      this.view = event.view
      this.series = event.series
      this.$emit('view-selector-change', event)
    }
  }
})
</script>

<style lang="scss" scoped>
.total-plan-dashboard-header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  &__actions,
  &__action {
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  &__action {
    font-weight: normal;

    &:last-child {
      margin-right: -8px;
    }
  }

  &__action-btn {
    margin: -8px 0;
  }
}
</style>
