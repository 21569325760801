<template>
  <cargill-section :layer="2" class="total-plan-execution-consolidated">
    <cargill-section-header
      class="total-plan-execution-consolidated__header"
      color="#2B323F"
    >
      <div>
        <span>{{ translate('title') }}</span>
      </div>

      <total-plan-dashboard-header-actions
        :filter-definition="filterDefinition"
        :view-selector-config="viewSelectorConfig"
        @on-export-data="onExport"
        @filter-change="onFilterChange"
        @view-selector-change="onViewSelectorChange"
      />
    </cargill-section-header>

    <cargill-section-content
      class="total-plan-execution-consolidated__dashboards"
    >
      <bc-row dense>
        <bc-col cols="12" lg="8">
          <dashboard-chart
            ref="DashboardMonth"
            class="total-plan-program-dashboard"
            :on-load="onLoadChart"
            :on-export="onExportFake"
            :allow-actions="service.allowActions"
            :base-filter="fakeFilter"
            :supressChartStack="!isStacked()"
            :isVerticalLabel="true"
          >
          </dashboard-chart>
        </bc-col>
        <bc-col cols="12" lg="4">
          <dashboard-chart
            ref="DashboardConsolidated"
            class="total-plan-program-dashboard"
            :on-load="onLoadConsolidatedChart"
            :on-export="onExportFake"
            :allow-actions="service.allowActions"
            :base-filter="fakeFilter"
            :supressChartStack="!isStacked()"
            :isVerticalLabel="true"
          >
          </dashboard-chart>
        </bc-col>
      </bc-row>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'

import { DashboardChart } from '../dashboards'
import TotalPlanDashboardHeaderActions from './TotalPlanDashboardHeaderActions.vue'
import service from '../../api/totalPlanService'

export default {
  name: 'TotalPlanDashboardDomesticMarketProgram',
  data() {
    return {
      endpoint: 'programDomesticMarket',
      service: service,
      chart: {},
      consolidatedChart: {},
      filterDefinition: [],
      filter: {},
      fakeFilter: {},
      viewSelectorConfig: null,
      viewSelector: {}
    }
  },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  components: {
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    TotalPlanDashboardHeaderActions,
    DashboardChart
  },
  created() {},
  async mounted() {
    this.viewSelectorConfig = await service.getViewSelectorConfig(this.endpoint)
    this.viewSelector = {
      view: this.viewSelectorConfig.viewDefault,
      series: this.viewSelectorConfig.seriesDefault
    }
    await this.loadChart()
    this.filterDefinition = this.chart.filters
  },

  methods: {
    isStacked() {
      return this.viewSelector?.view != this.viewSelectorConfig?.viewDefault
    },
    translate(key) {
      return this.$t(
        'controlTower.pages.totalPlanDashboard.domesticMarketProgram.' + key
      )
    },
    async loadChart() {
      const filter = {
        ...this.viewSelector,
        ...this.baseFilter,
        ...this.filter
      }
      this.resetChart()
      const result = await service.getCompleteChart(
        this.endpoint,
        this.chart,
        this.consolidatedChart,
        filter
      )
      this.chart = result.chart
      this.consolidatedChart = result.consolidatedChart
      this.fakeFilter = {}
    },
    resetChart() {
      this.chart = {}
      this.consolidatedChart = {}
      this.fakeFilter = {}
    },
    async onExport() {
      await service.getChartExcel(this.endpoint, this.chart, this.baseFilter)
    },
    async onLoadChart() {
      return await Promise.resolve(this.chart)
    },
    async onLoadConsolidatedChart() {
      return await Promise.resolve(this.consolidatedChart)
    },
    onViewSelectorChange(viewSelector) {
      this.viewSelector = viewSelector
      this.loadChart()
    },
    onFilterChange(filter) {
      this.filter = filter
      this.chart.filters.forEach((field) => {
        field.modelApplied = filter[field.name]
      })
      this.loadChart()
    },
    onExportFake() {},
    getChildRef() {
      return this.$refs.DashboardMonth
    },
    getChildConsolidatedRef() {
      return this.$refs.DashboardConsolidated
    }
  },
  watch: {
    baseFilter: {
      deep: true,
      handler() {
        this.loadChart()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.total-plan-execution-consolidated {
  padding: 10px 0 0;

  &__header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  &__dashboards {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
